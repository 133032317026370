import request from '@/auth/jwt/useJwt'

/*  查询列表  */
// eslint-disable-next-line arrow-body-style
export function query() {
  return request.axiosIns({
    url: 'organization/_all/tree?paging=false&terms%5B0%5D.column=typeId&terms%5B0%5D.value=org',
    method: 'get',
  })
}
/* 编辑机构信息  */
export function PATCH(data) {
  return request.axiosIns({
    url: 'organization',
    method: 'PATCH',
    data,
  })
}

/* 删除 */
export function remove(id) {
  return request.axiosIns({
    url: `organization/${id}`,
    method: 'DELETE',
  })
}

/* 查询已经绑定的用户 */
export function getOrgUserList(pageIndex, pageSize, params) {
  let requestUrl = `user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$in-dimension$org&terms[0].value=${params.orgId}`
  let index = 1
  if (params.name) {
    requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
    index++
  }
  if (params.username) {
    requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
/* 解除绑定（多个） */
export function unbind(orgId, params) {
  return request.axiosIns({
    url: `organization/${orgId}/users/_unbind`,
    method: 'post',
    data: params,
  })
}
/* 绑定用户（多个） */
export function bind(orgId, params) {
  return request.axiosIns({
    url: `organization/${orgId}/users/_bind`,
    method: 'post',
    data: params,
  })
}
/* 查询可以绑定的用户（已经绑定的不查询） */
export function queryUnbindUsers(orgId, pageIndex, pageSize, params) {
  let requestUrl = `user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$in-dimension$org$not&terms[0].value=${orgId}`
  if (params) {
    let index = 1
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.username) {
      requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

export function queryCategoryAssets(orgId) {
  return request.axiosIns({
    url: `device/category/_tree?paging=false&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&terms%5B0%5D.column=id&terms%5B0%5D.termType=dim-assets&terms%5B0%5D.value=%7B%22assetType%22%3A%22deviceCategory%22%2C%22targets%22%3A%5B%7B%22type%22%3A%22org%22%2C%22id%22%3A%22${orgId}%22%7D%5D%7D`,
    method: 'get',
  })
}

export function queryUnbindCategoryAssets(orgId) {
  return request.axiosIns({
    url: `device/category/_tree?paging=false&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&terms%5B0%5D.column=id&terms%5B0%5D.termType=dim-assets%24not&terms%5B0%5D.value=%7B%22assetType%22%3A%22deviceCategory%22%2C%22targets%22%3A%5B%7B%22type%22%3A%22org%22%2C%22id%22%3A%22${orgId}%22%7D%5D%7D`,
    method: 'get',
  })
}
/* 解除绑定（多个） */
export function unbindCategory(orgId, ids) {
  let value = {
    assetIdList: ids,
    assetType: 'deviceCategory',
    targetId: orgId,
    targetType: 'org',
  }
  return request.axiosIns({
    url: '/assets/unbind/deviceCategory',
    method: 'post',
    data: value,
  })
}
/* 绑定（多个） */
export function bindCategory(orgId, ids, permission) {
  let value = {
    assetIdList: ids,
    assetType: 'deviceCategory',
    targetId: orgId,
    targetType: 'org',
    permission,
  }
  return request.axiosIns({
    url: '/assets/bind/deviceCategory',
    method: 'post',
    data: value,
  })
}

export function queryProductAssets(orgId, pageIndex, pageSize, params, not) {
  let value = {
    assetType: 'product',
    targets: [{ type: 'org', id: orgId }],
  }
  let requestUrl = `/device-product/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&&terms[0].column=id&terms[0].termType=dim-assets${not ? '$not' : ''}&terms[0].value=${JSON.stringify(value)}&sorts[0].name=id&sorts[0].order=desc`
  let index = 1
  if (params) {
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index++
    }
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.state !== undefined && params.state !== null) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

/* 解除绑定（多个） */
export function unbindProduct(orgId, ids) {
  let value = {
    assetIdList: ids,
    assetType: 'product',
    targetId: orgId,
    targetType: 'org',
  }
  return request.axiosIns({
    url: '/assets/unbind/product',
    method: 'post',
    data: value,
  })
}
/* 绑定（多个） */
export function bindProduct(orgId, ids, permission) {
  let value = {
    assetIdList: ids,
    assetType: 'product',
    targetId: orgId,
    targetType: 'org',
    permission,
  }
  return request.axiosIns({
    url: '/assets/bind/product',
    method: 'post',
    data: value,
  })
}

export function queryDeviceAssets(orgId, pageIndex, pageSize, params, not) {
  let value = {
    assetType: 'device',
    targets: [{ type: 'org', id: orgId }],
  }
  let requestUrl = `/device-instance/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&&terms[0].column=id&terms[0].termType=dim-assets${not ? '$not' : ''}&terms[0].value=${JSON.stringify(value)}&sorts[0].name=id&sorts[0].order=desc`
  let index = 1
  if (params) {
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index++
    }
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.productId) {
      requestUrl += `&terms[${index}].column=productId&terms[${index}].value=${params.productId}`
      index++
    }
    if (params.state !== undefined && params.state !== null) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

/* 解除绑定（多个） */
export function unbindDevice(orgId, ids) {
  let value = {
    assetIdList: ids,
    assetType: 'device',
    targetId: orgId,
    targetType: 'org',
  }
  return request.axiosIns({
    url: '/assets/unbind/device',
    method: 'post',
    data: value,
  })
}
/* 绑定（多个） */
export function bindDevice(orgId, ids, permission) {
  let value = {
    assetIdList: ids,
    assetType: 'device',
    targetId: orgId,
    targetType: 'org',
    permission,
  }
  return request.axiosIns({
    url: '/assets/bind/device',
    method: 'post',
    data: value,
  })
}
