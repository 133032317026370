import { render, staticRenderFns } from "./OrgUsers.vue?vue&type=template&id=4c1ca424&scoped=true&"
import script from "./OrgUsers.vue?vue&type=script&lang=js&"
export * from "./OrgUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1ca424",
  null
  
)

export default component.exports