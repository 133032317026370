<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      :card="false"
      :dataPreHandle="dataPreHandle"
      @search="searchHandle"
      @rowUnbind="rowUnbind"
      @rowSelected="rowSelected">
      <template slot="actions">
        <x-button name="绑定" icon="PlusIcon" @click="showBindModal"></x-button>
        <x-button name="解绑" icon="XIcon" variant="light-danger" class="ml-1" @click="unbind"></x-button>
      </template>
    </x-el-table>
    <b-modal
      id="bind-user"
      size="xl"
      :visible="showModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="保存"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="选择产品"
      centered
      @hidden="hideModal"
      @ok="enterModal">
      <x-el-table
        ref="bind_table"
        :card="false"
        :options="bindOptions"
        @search="searchProductHandle"
        @rowSelected="rowProductSelected">
      </x-el-table>
      <b-form-group v-if="selectUnbindRows && selectUnbindRows.length > 0">
        <span class="x-require-indicator font-medium-2 x-text-bold x-pr-4px">*</span>
        <span class="x-text-bold ">请选择资产权限：</span>
        <b-form-checkbox-group
          v-model="selectPermissions"
          :options="permissionOptions"
          class="ml-1 mt-1"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormGroup, BFormCheckboxGroup } from 'bootstrap-vue'
import XElTable from '@/@core/components/cx/table/XElTable.vue'
import XButton from '@/@core/components/cx/button/XButton.vue'
import {
  queryProductAssets, unbindProduct, bindProduct,
} from '@/api/system/setup/org'
import {
  count,
} from '@/api/device-manage/product-list'

export default {
  components: {
    XElTable,
    XButton,
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    org: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      selectRows: [],
      selectPermissions: [],
      selectUnbindRows: [],
      permissionOptions: [
        { text: '读', value: 'read' },
        { text: '写', value: 'save' },
        { text: '删除', value: 'delete' },
      ],
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [
          { action: 'unbind', tip: '解绑', icon: 'XIcon' },
        ],
        columns: [{
          label: '图片',
          prop: 'photoUrl',
          type: 'image',
          labelShow: false,
          searchShow: false,
        }, {
          label: '产品ID',
          prop: 'id',
          editDisable: true,
          sortable: true,
        }, {
          label: '产品名称',
          prop: 'name',
          sortable: true,
          linkUrl: '/device-center/products/view/{id}',
        }, {
          label: '设备数量',
          prop: 'count',
          linkUrl: '/device-center/device-manager/devices?productId={id}',
          sortable: true,
          editShow: false,
          addShow: false,
          searchShow: false,
          rowCls: 'text-center',
        }, {
          label: '发布状态',
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          addShow: false,
          dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
          sortable: true,
          editShow: false,
        },
        ],
      },
      bindOptions: {
        contentHeight: '55vh',
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [],
        columns: [{
          label: '图片',
          prop: 'photoUrl',
          type: 'image',
          labelShow: false,
          searchShow: false,
        }, {
          label: '产品ID',
          prop: 'id',
          editDisable: true,
          sortable: true,
        }, {
          label: '产品名称',
          linkUrl: '/device-center/products/view/{id}',
          prop: 'name',
          sortable: true,
        }, {
          label: '发布状态',
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          addShow: false,
          dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
          sortable: true,
          editShow: false,
        },
        ],
      },
    }
  },
  watch: {
    org(value) {
      this.org = value
      if (this.$refs.table) {
        this.$refs.table.__searchHandle()
      }
    },
  },
  methods: {
    dataPreHandle(data, done) {
      count(data.id).then(resp => {
        data.count = resp.data.result
        done(data)
      })
    },
    searchHandle(page, params, done) {
      if (!this.org) {
        done([], 0)
        return
      }
      queryProductAssets(this.org.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    getIds(data, ids) {
      ids.push(data.id)
      if (data.children) {
        data.children.forEach(item => {
          ids.push(item.id)
          this.getIds(item, ids)
        })
      }
      return ids
    },
    rowUnbind(data, done) {
      let ids = []
      this.getIds(data, ids)
      unbindProduct(this.org.id, ids).then(() => {
        done()
      })
    },
    unbind() {
      if (!this.selectRows || this.selectRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      unbindProduct(this.org.id, this.selectRows.map(item => item.id)).then(() => {
        this.$xtoast.success('解绑成功！')
        this.selectRows = []
        this.$refs.table.__searchHandle()
      })
    },
    rowSelected(rows) {
      this.selectRows = rows
    },
    showBindModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
      this.selectUnbindRows = []
      this.selectPermissions = []
    },
    enterModal(bvModalEvt) {
      if (!this.selectUnbindRows || this.selectUnbindRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        bvModalEvt.preventDefault()
        return
      }
      if (!this.selectPermissions || this.selectPermissions.length === 0) {
        this.$xtoast.warning('请选择资产权限！')
        bvModalEvt.preventDefault()
        return
      }
      bindProduct(this.org.id, this.selectUnbindRows.map(item => item.id), this.selectPermissions).then(() => {
        this.$xtoast.success('绑定成功！')
        this.$refs.bind_table.__searchHandle()
        this.$refs.table.__searchHandle()
        this.showModal = false
      })
    },
    searchProductHandle(page, params, done) {
      if (!this.org) {
        done([], 0)
        return
      }
      queryProductAssets(this.org.id, page.pageIndex - 1, page.pageSize, params, true).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowProductSelected(rows) {
      this.selectUnbindRows = rows
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
