<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      :card="false"
      @search="searchHandle"
      @rowUnbind="rowUnbind"
      @rowSelected="rowSelected">
      <template slot="actions">
        <x-button name="绑定" icon="PlusIcon" @click="showBindModal"></x-button>
        <x-button name="解绑" icon="XIcon" variant="light-danger" class="ml-1" @click="unbind"></x-button>
      </template>
    </x-el-table>
    <b-modal
      id="bind-user"
      size="xl"
      :visible="showModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="保存"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="选择设备"
      centered
      @hidden="hideModal"
      @ok="enterModal">
      <x-el-table
        ref="bind_table"
        :card="false"
        :options="bindOptions"
        @search="searchProductHandle"
        @rowSelected="rowProductSelected">
      </x-el-table>
      <b-form-group v-if="selectUnbindRows && selectUnbindRows.length > 0">
        <span class="x-require-indicator font-medium-2 x-text-bold x-pr-4px">*</span>
        <span class="x-text-bold ">请选择资产权限：</span>
        <b-form-checkbox-group
          v-model="selectPermissions"
          :options="permissionOptions"
          class="ml-1 mt-1"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormGroup, BFormCheckboxGroup } from 'bootstrap-vue'
import XElTable from '@/@core/components/cx/table/XElTable.vue'
import XButton from '@/@core/components/cx/button/XButton.vue'
import {
  queryDeviceAssets, unbindDevice, bindDevice,
} from '@/api/system/setup/org'

export default {
  components: {
    XElTable,
    XButton,
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    org: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      selectRows: [],
      selectPermissions: [],
      selectUnbindRows: [],
      permissionOptions: [
        { text: '读', value: 'read' },
        { text: '写', value: 'save' },
        { text: '删除', value: 'delete' },
      ],
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [
          { action: 'unbind', tip: '解绑', icon: 'XIcon' },
        ],
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: true,
          searchShow: false,
        }, {
          label: '设备名称',
          labelShow: true,
          prop: 'name',
          linkUrl: '/device-center/device/view/{id}',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入设备名称',
          },
        }, {
          label: '产品',
          labelShow: true,
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          editDisable: true,
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          onDictSelected: (option, column, formData) => {
            column[2].dictData.forEach(product => {
              if (option === product.value) {
                formData.productName = product.label
              }
            })
          },
          searchShow: false,
        }, {
          label: '产品名称',
          labelShow: true,
          prop: 'productName',
          editShow: false,
          addShow: false,
          viewShow: false,
          rowShow: false,
          searchShow: false,
        }, {
          label: '注册时间',
          labelShow: true,
          prop: 'registryTime',
          type: 'datetime',
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'notActive', text: '未启用' }, { value: 'online', text: '在线' }, { value: 'offline', text: '离线' }],
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
        },
        ],
      },
      bindOptions: {
        contentHeight: '55vh',
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [],
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: true,
          searchShow: false,
        }, {
          label: '设备名称',
          labelShow: true,
          prop: 'name',
          linkUrl: '/device-center/device/view/{id}',
          searchShow: true,
        }, {
          label: '产品',
          labelShow: true,
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          editDisable: true,
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          onDictSelected: (option, column, formData) => {
            column[2].dictData.forEach(product => {
              if (option === product.value) {
                formData.productName = product.label
              }
            })
          },
          searchShow: true,
        }, {
          label: '产品名称',
          labelShow: true,
          prop: 'productName',
          editShow: false,
          addShow: false,
          viewShow: false,
          rowShow: false,
          searchShow: false,
        }, {
          label: '注册时间',
          labelShow: true,
          prop: 'registryTime',
          type: 'datetime',
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'notActive', text: '未启用' }, { value: 'online', text: '在线' }, { value: 'offline', text: '离线' }],
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
        },
        ],
      },
    }
  },
  watch: {
    org(value) {
      this.org = value
      if (this.$refs.table) {
        this.$refs.table.__searchHandle()
      }
    },
  },
  methods: {
    searchHandle(page, params, done) {
      if (!this.org) {
        done([], 0)
        return
      }
      queryDeviceAssets(this.org.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    getIds(data, ids) {
      ids.push(data.id)
      if (data.children) {
        data.children.forEach(item => {
          ids.push(item.id)
          this.getIds(item, ids)
        })
      }
      return ids
    },
    rowUnbind(data, done) {
      let ids = []
      this.getIds(data, ids)
      unbindDevice(this.org.id, ids).then(() => {
        done()
      })
    },
    unbind() {
      if (!this.selectRows || this.selectRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      unbindDevice(this.org.id, this.selectRows.map(item => item.id)).then(() => {
        this.$xtoast.success('解绑成功！')
        this.selectRows = []
        this.$refs.table.__searchHandle()
      })
    },
    rowSelected(rows) {
      this.selectRows = rows
    },
    showBindModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
      this.selectUnbindRows = []
      this.selectPermissions = []
    },
    enterModal(bvModalEvt) {
      if (!this.selectUnbindRows || this.selectUnbindRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        bvModalEvt.preventDefault()
        return
      }
      if (!this.selectPermissions || this.selectPermissions.length === 0) {
        this.$xtoast.warning('请选择资产权限！')
        bvModalEvt.preventDefault()
        return
      }
      bindDevice(this.org.id, this.selectUnbindRows.map(item => item.id), this.selectPermissions).then(() => {
        this.$xtoast.success('绑定成功！')
        this.$refs.bind_table.__searchHandle()
        this.$refs.table.__searchHandle()
        this.showModal = false
      })
    },
    searchProductHandle(page, params, done) {
      if (!this.org) {
        done([], 0)
        return
      }
      queryDeviceAssets(this.org.id, page.pageIndex - 1, page.pageSize, params, true).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowProductSelected(rows) {
      this.selectUnbindRows = rows
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
