<template>
  <div>
    <div class="ml-5 mt-1" v-if="org">
      <span class="font-large-1 x-text-bold">{{ org.name }}</span>
      <div class="d-flex align-items-center">
        <div class="text-muted mr-1">编码：</div>
        <div class="x-text-bold">{{ org.id }}</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-muted mr-1">描述：</div>
        <div :class="org.describe ? '' : 'text-muted'">{{ org.describe ? org.describe : '暂无' }}</div>
      </div>
    </div>
    <!--<el-divider></el-divider>-->
    <div class="x-card x-no-shadow mt-2 mb-2 pt-1"></div>
    <b-tabs class="pl-3 pr-3 pb-2">
      <b-tab>
        <template #title>
          <div class="d-flex align-items-center">
            <feather-icon icon="GridIcon" class="ml-1"/>
            <span class="mr-1">分类</span>
          </div>
        </template>
        <category-assets :org="org"></category-assets>
      </b-tab>
      <b-tab>
        <template #title>
          <div class="d-flex align-items-center">
            <feather-icon icon="BoxIcon" class="ml-1" />
            <span class="mr-1">产品</span>
          </div>
        </template>
        <product-assets :org="org"></product-assets>
      </b-tab>
      <b-tab>
        <template #title>
          <div class="d-flex align-items-center">
            <feather-icon icon="HardDriveIcon" class="ml-1" />
            <span class="mr-1">设备</span>
          </div>
        </template>
        <device-assets :org="org"></device-assets>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import CategoryAssets from './CategoryAssets.vue'
import ProductAssets from './ProductAssets.vue'
import DeviceAssets from './DeviceAssets.vue'

export default {
  components: {
    BTabs,
    BTab,
    CategoryAssets,
    ProductAssets,
    DeviceAssets,
  },
  props: {
    org: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  watch: {
    org(value) {
      this.org = value
    },
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
</style>
