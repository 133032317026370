<template>
  <div v-toggle="target" v-ripple.400="$x.ripple[rippleColor]" :class="innerCls" class="btn" @click="clicked">
    <feather-icon :icon="icon" class="mr-50"></feather-icon>
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'XButton',
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    rippleColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    innerCls() {
      let cls = this.size ? `btn-${this.size} ` : ''
      cls += `btn-${this.variant}`
      return cls
    },
  },
  methods: {
    clicked() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>

</style>
