<template>
  <div>
    <x-el-table
      ref="table"
      no-page
      :options="options"
      @search="searchHandle"
      @beforeShow="beforeShow"
      @rowAdd="rowEdit"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowBindUser="rowBindUser"
      @rowAddChild="rowAddChild"
      @rowAssets="rowAssets"
      @rowAuthz="rowAuthz">
    </x-el-table>
    <b-sidebar
      id="sidebar-bind-user"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="75%"
      title="用户绑定"
      @hidden="bindHide"
    >
      <org-users
        :org="org"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-assets"
      bg-variant="white"
      right
      backdrop
      shadow
      width="75%"
      title="资产分配"
      @hidden="bindHide"
    >
      <org-assets
        :org="org"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-authz"
      v-model="authzShow"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="权限分配"
      right
      backdrop
      shadow
      width="45%"
      @hidden="authzHide"
    >
      <authz-form
        :targetId="targetId"
        targetType="org"
        @success="authzHide"
      />
    </b-sidebar>
  </div>
</template>

<script>
import XElTable from '@/@core/components/cx/table/XElTable.vue'
import {
  BSidebar,
} from 'bootstrap-vue'
import {
  query, PATCH, remove,
} from '@/api/system/setup/org'
import {
  formatDate2,
} from '@/api/timeFormatUtils'
import AuthzForm from '@/views/system/setup/utils/AuthzForm.vue'
import OrgUsers from './OrgUsers.vue'
import OrgAssets from './OrgAssets.vue'

export default {
  filters: {
    formatDate(time) {
      const date = new Date(time)
      return formatDate2(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {
    XElTable,
    OrgUsers,
    BSidebar,
    AuthzForm,
    OrgAssets,
  },
  data() {
    return {
      authzShow: false,
      // 赋权参数
      targetId: undefined,
      parentId: undefined,
      org: {},
      options: {
        actions: [
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
          { action: 'bindUser', tip: '绑定用户', icon: 'UsersIcon', target: 'sidebar-bind-user' },
          { action: 'addChild', name: '添加下级', icon: 'AnchorIcon' },
          { action: 'authz', name: '权限分配', icon: 'PocketIcon', target: 'sidebar-authz' },
          { action: 'assets', name: '资产分配', icon: 'AlignCenterIcon', target: 'sidebar-assets' },
        ],
        columns: [{
          label: '机构编码',
          prop: 'id',
          searchShow: false,
          editDisable: true,
          rules: {
            rule: 'required',
            message: '请输入机构编码',
          },
        }, {
          label: '机构名称',
          prop: 'name',
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入机构名称',
          },
        }, {
          label: '排序值',
          prop: 'sortIndex',
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入排序值',
          },
        }, {
          label: '描述',
          type: 'textarea',
          prop: 'describe',
          searchShow: false,
        },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      query().then(resp => {
        done(resp.data.result, resp.data.result.total)
      })
    },
    beforeShow(type, data) {
      // 确保parentId只使用一次
      if (this.parentId) {
        data.parentId = this.parentId
        this.parentId = undefined
      }
    },
    rowEdit(data, done) {
      PATCH(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowBindUser(data) {
      this.org = data
      this.targetId = data.id
    },
    rowAddChild(data) {
      this.parentId = data.id
      this.$refs.table.showAddForm()
    },
    rowAuthz(data) {
      this.targetId = data.id
    },
    rowAssets(data) {
      this.org = data
    },
    bindHide() {
      this.org = undefined
      this.targetId = undefined
    },
    authzHide() {
      this.targetId = undefined
      this.authzShow = false
    },
  },
}
</script>

<style lang="scss">
</style>
